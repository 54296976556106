import React from "react"
import {Link} from "react-router-dom"
import "./navbar.css"
import acaciaLogo from "../../../assets/logos/acacia-logo.png"

function NavBar() {
    return(
        <nav className="navbar">
            <Link to="/">
                <img src={acaciaLogo} alt="acacia logo" className="navbar--logo"/>
            </Link>
        </nav>
    )
    
}

export default NavBar
