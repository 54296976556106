import React from "react"
import "./buttons.css"

function SecondaryCTA(props) {
    return (
        <div className={`button secondary ${props.class}`}>
            <div className="button-text">{props.text}</div>
        </div>
    )
}
export default SecondaryCTA