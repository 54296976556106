import React from "react"
import {Link} from "react-router-dom"
import "./footer.css"
import iconFB from "../../../assets/icons/icon-facebook.png"
import iconIG from "../../../assets/icons/icon-instagram.png"
import iconTW from "../../../assets/icons/icon-twitter.png"
import iconLI from "../../../assets/icons/icon-linkedin.png"
import iconStores from "../../../assets/icons/icon-stores.png"


function Footer() {

    const currentYear = new Date().getFullYear()

    return (
        <footer className="footer">
            <div className="footer-main">

                <div className="footer-section">
                    <div className="footer-title">Bientôt disponible sur</div>
                    <img src={iconStores} alt="app stores" className="store-logo"/>
                </div>

                <div className="footer-section">
                    <div className="footer-title">Rejoignez-nous !</div>
                    <div className="social-logos">
                        <a href="https://www.instagram.com/acacia_app/" target="blank">
                            <img src={iconIG} alt="instagram logo" className="social-logo"/>
                        </a>
                        <a href="https://www.linkedin.com/in/maximecourtaigne/" target="blank">
                            <img src={iconLI} alt="linkedin logo" className="social-logo"/>
                        </a>
                        <a href="https://www.facebook.com/Acaciapp-104549458894874" target="blank">
                            <img src={iconFB} alt="facebook logo" className="social-logo"/>
                        </a>
                        <a href="https://www.instagram.com/acacia_app/" target="blank">
                            <img src={iconTW} alt="twitter logo" className="social-logo"/>
                        </a>
                    </div>
                </div>

                <div className="footer-section">
                    <div className="footer-title">acacia.</div>
                    <div className="footer-list">
                        <Link to="/about" className="link-router"><p>Qui sommes-nous ?</p></Link>
                        <Link to="/manifesto" className="link-router"><p>Notre Manifesto</p></Link>
                        <Link to="/partners" className="link-router"><p>Devenir contributeur</p></Link>
                        <Link to="/contact" className="link-router"><p>Nous contacter</p></Link>
                        <Link to="/legal" className="link-router"><p>Mentions légales</p></Link>
                    </div>
                </div>
            </div>

            <div className="footer-copyright">
                <div className="footer-title">Copyright © {currentYear} acacia. Tous droit réservés</div>
            </div>
        </footer>
    )
}
export default Footer