import React, {useState} from "react"
import {Link} from "react-router-dom"
import {NavBar, Footer, PrimaryCTA, SecondaryCTA} from "../../../components"
import "./soonPage.css"


function SoonPage(props) {

    const [formData, setFormData] = useState(
        {
            firstName: "",
            email: "",
            acceptance: false,
        }
    )
    
    const [isSubmitted, setIsSubmitted] = useState(false)

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

    function handleSubmit(event) {
        event.preventDefault()
        setIsSubmitted(true)

        const options = {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'api-key': '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'
            },
            body: JSON.stringify({
                attributes: {
                    FIRSTNAME: formData.firstName,
                    ACCEPTANCE: formData.acceptance,
                },
                listIds: [3],
                updateEnabled: false,
                email: formData.email
            })
       }
          
        fetch('https://api.sendinblue.com/v3/contacts', options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));

    }

    let messageSent = "Nous avons bien enregistré vos informations. On vous donne des nouvelles rapidement !"

    return (
        <div className="soon-page">
            <NavBar />
            <main className="main-container">
                <section className="header-bg">
                    <div className="page-header">
                        <div className="header-top">
                            <h1><span className="special-font">Bientôt </span> disponible !</h1>
                            <h3>Renseignez votre email pour être au courant des <strong>avancées du projet</strong> et de la <strong>sortie de l’application.</strong></h3>
                        </div>
                        {/* Header image */}
                    
                        <div className="subscription-form">
                            <form className="contactform" onSubmit={handleSubmit}>

                                <label className="contactform-label" htmlFor="subject">Prénom</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    required
                                    placeholder="Nicolas"
                                    onChange={handleChange}
                                    name="firstName"
                                    value={formData.firstName}
                                />

                                <label className="contactform-label" htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    required
                                    placeholder="nicolas.hublot@gmail.com"
                                    onChange={handleChange}
                                    name="email"
                                    value={formData.email}
                                />
                                <div className="checkbox-acceptance">
                                    <input 
                                        type="checkbox" 
                                        id="acceptance" 
                                        required
                                        checked={formData.acceptance}
                                        onChange={handleChange}
                                        name="acceptance"
                                    />
                                    <label className="contactform-label checkbox" htmlFor="acceptance">J'accepte de recevoir des informations de la part d'acacia.</label>
                                </div>
                                

                                {isSubmitted && <div className="success-message">{messageSent}</div>}

                                <button>
                                    <PrimaryCTA text="SUIVRE L'AVENTURE" class={isSubmitted && "cta-disabled"} />
                                </button>

                            </form>   
                        </div>
                    </div>
        
                    {isSubmitted ?
                            <Link to="/" className="link-router">
                                <SecondaryCTA text="RETOUR À L'ACCUEIL"/>
                            </Link> :
                            ""}
                    
                    {/* <FaChevronDown className="chevron-bottom"/> */}
                </section>
            </main>
            <Footer />
        </div>  
    )
}

export default SoonPage