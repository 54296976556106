import React from "react"
import "./actionThumb.css"
import {AcaciaIcon} from "../../../components"
import adjustActionColor from "../../../hooks/adjustActionColor"

function ActionThumbMini(props) {
    
    let thumbColor = adjustActionColor(props.category)

    return (
        <div className="action-thumb-mini" style={{borderColor:thumbColor}}>
            <div className="thumb-mini-title" style={{color:thumbColor}}>{props.title}</div>
            <div className="thumb-mini-score">
                <span className="thumb-mini-points" style={{color:thumbColor}}>{props.points}</span>
                <AcaciaIcon height="20" width="20" color={thumbColor} />
            </div>
        </div>
    )
}

export default ActionThumbMini