import React from "react"
import {NavBar, Footer} from "../../../components"
import {Accordion, AccordionSummary, AccordionDetails, Typography} from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { StyledEngineProvider } from '@mui/material/styles'
import "./manifestoPage.css"

function ManifestoPage() {
    return (
        <div className="manifesto-page">
            <NavBar />
            <main className="main-container">
                <section className="header-bg">
                    <div className="page-header">
                        <div className="header-top">
                            <h1>Des <span className="special-font"> actions </span> plutôt que des mots</h1>
                            <h3>
                                Nos <strong>modes de consommation</strong> actuels sont en train de <strong>détruire notre santé</strong> et celle de notre <strong>planète</strong>. <br/><br/>
                                Il n’est jamais trop tard pour <strong>agir</strong>. <br/><br/>
                                Nous sommes convaincus que la somme de <strong>petites actions individuelles</strong> permettra d’accomplir les <strong>grands défis collectifs</strong> que nous ne pouvons plus ignorer.
                            </h3>
                        </div>
                    </div>
                </section>

                <section className="section-bg orange-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Vous souhaitez en <span className="special-font"> savoir </span> plus ? </h2>
                        </div>
                        
                        <div className="accordion-container">
                            <StyledEngineProvider injectFirst> {/* To overide MUI styling by injecting custom CSS */}
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                    <Typography
                                        component="h2"
                                    >
                                        L’évolution d’Homo Sapiens
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography>
                                        <p>Les premières traces de nos ancêtres Homo Sapiens remontent à plus de 300 000 années avant JC. Ils ont vécu en <span className="bold">harmonie avec la nature</span> pendant des centaines de millénaires. Leurs déchets étaient uniquement d’ordre organique et leur alimentation était constituée exclusivement de produits naturels dont la disponibilité fluctuait selon les territoires, au rythme des saisons. Naturellement nomades, ils évoluaient en petits groupes de quelques individus et se déplaçaient régulièrement afin d’avoir accès aux ressources nécessaires à leurs besoins. </p>
                                        <p>Ce mode de vie plutôt rude a poussé Homo Sapiens à se regrouper en clans de quelques centaines d’individus afin de se faciliter le quotidien. C’est la <span className="bold">révolution cognitive</span> (70 000 avant JC). La sédentarisation progressive qui en découle les a incité à modifier sensiblement cet équilibre naturel via le développement de l’élevage et de l’agriculture. </p>
                                        <p>Au fil des millénaires, les campements sont devenus des villages, puis les villages sont devenus des villes. La majorité des individus s’est donc converti à l’élevage et l’agriculture, qui sont devenus de plus en plus intensifs et sélectifs. On parle alors de la <span className="bold">révolution agricole</span> (11 000 avant JC). C’est à partir de cette période que la population a fortement augmenté au prix de régimes alimentaires moins variés et de rythmes naturels perturbés. </p>
                                        <p>Cette vie en communauté a en revanche permis une meilleure coopération entre les individus. Ce qui a eu pour effet de favoriser rapidement le progrès technique jusqu’à la <span className="bold">révolution scientifique</span> (au 16é siècle de notre ère) avec l’avènement des mathématiques, de la physique, de la chimie, de la biologie et de l’astronomie. Cette dernière est le point de départ d’une <span className="bold">très forte accélération du développement</span> qui a profondément bouleversé le rapport d'Homo Sapiens à la nature. </p>
                                        <p>La <span className="bold">révolution industrielle</span> qui en découle (à la fin du 18é siècle) est le changement le plus radical et le plus rapide de l’histoire. En quelques dizaines de décennies, nous sommes passé d’une économie essentiellement agraire à une production de biens manufacturés à grande échelle. Les paysans deviennent ouvriers, les cycles naturels ne sont plus respectés et la production de déchets explose. </p>
                                        <p>Dans la seconde moitié du 20é siècle (soit seulement 1 siècle plus tard), c’est au tour de la <span className="bold">révolution de l’information</span> d’apporter son lot de changements structurels. Internet et les ordinateurs redessinent considérablement les frontières de l’humanité, l’accès à l’information et les modalités d’interactions entre les individus. </p>
                                        <p>Ces deux dernières révolutions (industrielle et information) ont profondément modifié nos <span className="bold">modes de consommation</span> et nos <span className="bold">styles de vie</span>. </p>
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                    <Typography
                                        component="h2"
                                    >
                                        L’ère du “Toujours plus”
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography>
                                        <p><span className="bold">Tout, tout de suite</span>. Cela résume bien l’état d’esprit d’Homo Sapiens au 21é siècle. </p>
                                        <p>Nos modes de consommation et nos style de vie nous pousse à faire <i>toujours plus</i> vite, à devenir <i>toujours plus</i> riche, à aller <i>toujours plus</i> loin, à faire <i>toujours plus</i> simple, à rendre <i>toujours plus</i> abordable, à devenir <i>toujours plus</i> libre... et la liste pourrait être longue. </p>
                                        <p>Homo Sapiens est désormais un <span className="bold">éternel insatisfait</span>, incapable de se contenter de ce qu’il a. Il pense systématiquement que l’herbe est plus verte ailleurs. </p>
                                        <p>Homo Sapiens devient de plus en plus <span className="bold">individualiste</span>, incapable de penser aux autres et aux générations futures. Il est motivé par l’assouvissement de ses besoins immédiats pour son petit confort personnel. </p>
                                        <p>Homo Sapiens est maintenant <span className="bold">déconnecté de la nature</span>, incapable de lui rendre ce qu’elle lui a donné. Il préfère lutter contre son évolution plutôt que d’aller dans son sens. </p>
                                        <p>Cette ère du “Toujours plus” a des conséquences dramatiques pour notre santé et celle de notre planète que nous ne pouvons plus ignorer. </p>
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                    <Typography
                                        component="h2"
                                    >
                                        Notre santé en péril
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography>
                                        <p>Nous avons tendance à l’oublier mais le corps humain est une machine formidable : très complexe, elle est aussi <span className="bold">très sensible aux variations</span> des différents paramètres qui la régule comme l’alimentation, le sommeil, l’activité physique, la lumière, la température et bien plus. Il est évident que nos modes de vie actuels bouleversent son équilibre, provoquant différentes <span className="bold">maladies chroniques</span> comme les cancers, les maladies cardiovasculaires, l’obésité, l’ostéoporose, le diabète, l’hypercholestérolémie...</p>
                                        <p>Une <a target="_blank" rel="noopener noreferrer" href="https://www.who.int/fr/news/item/09-12-2020-who-reveals-leading-causes-of-death-and-disability-worldwide-2000-2019#:~:text=Les%20maladies%20cardiaques%20sont%20rest%C3%A9es,millions%20de%20d%C3%A9c%C3%A8s%20en%202019.">étude récente</a> de l'Organisation Mondiale de la Santé démontre que ces maladies chroniques sont devenues la <span className="bold">première cause de mortalité</span> dans le monde, représentant 70% des décès en 2019. Une proportion en <span className="bold">très forte augmentation</span> sur les 20 dernières années puisqu’elle était de 40% en 2000. </p>
                                        <p><span className="bold">Tout s’accélère.</span></p>
                                        <p><span className="bold">Les causes ?</span> Les produits transformés, la malbouffe, les additifs, les pesticides, les antibiotiques, le stress, les écrans, le manque de sommeil, la sédentarité, les perturbateurs endocriniens, le sucre, la pollution atmosphérique... autant de facteurs de risque hérités de cette nouvelle ère du “Toujours plus”.  </p>
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                    <Typography
                                        component="h2"
                                    >
                                        L’urgence environnementale
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography>
                                        <p>Il est prouvé scientifiquement que nos modes de vie actuels bouleversent l’équilibre fragile de l’écosystème planétaire, provoquant des phénomènes dangereux pour sa <span className="bold">préservation</span> et la <span className="bold">survie des différentes espèces</span> y habitant.</p>
                                        <p>Le <a target="_blank" rel="noopener noreferrer" href="https://www.ipcc.ch/report/ar6/wg3/">dernier rapport du GIEC</a> met en évidence quelques statistiques effrayantes :</p>
                                        <ul>
                                            <li>En 2022, une personne sur deux dans le monde subit déjà des pénuries d’eau liées au dérèglement climatique</li>
                                            <li>En 2022, 50% des espèces vivantes étudiées sont déjà en train de migrer pour fuir les impacts du changement climatique</li>
                                            <li>Le risque est élevé de voir une partie de l'Amazonie se transformer en savane si le réchauffement climatique excède +1.5°C (la tendance actuelle est de +3.2°C à horizon 2050)</li>
                                            <li>Au cours de la dernière décennie, il y a eu 15 fois plus de morts à cause des inondations, de la sécheresse et des tempêtes dans les pays les plus vulnérables que dans les autres pays</li>
                                            <li>Depuis 2008, on compte déjà 20 millions de déplacés climatiques internes (à l’intérieur de leur propre pays)</li>
                                        </ul>
                                        <p><span className="bold">Tout s’accélère.</span></p>
                                        <p><span className="bold">Les causes ?</span> Principalement les émissions de gaz à effet de serre mais aussi la pollution des sols et des océans, la déforestation... et bien d’autres phénomènes aussi hérités de cette nouvelle ère du “Toujours plus”.</p>
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                    <Typography
                                        component="h2"
                                    >
                                        Les raisons d’un échec
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography>
                                        <p>Face aux dangers pour notre santé et à l’urgence environnementale générés par nos modes de vie actuels, il convient de se poser la question suivante : <span className="bold">comment en sommes-nous arrivés là ?</span></p>
                                        <p>Voici plusieurs éléments de réponse. </p>
                                        <ul>
                                            <li>L’ère du “Toujours plus” comme modèle de réussite social</li>
                                            <li>La priorité donnée au développement économique, sans considérer les externalités négatives sur notre santé et l’environnement</li>
                                            <li>Le coût économique inhérent à une vie plus saine et plus responsable</li>
                                            <li>L’impossibilité de coopérer efficacement au niveau international en raison d’intérêts divergents</li>
                                            <li>Le pouvoir des lobbies politiques et industriels</li>
                                            <li>Un manque de communication et d’éducation des individus sur ces sujets</li>
                                            <li>La politique de l’autruche consistant à ne pas vouloir voir la vérité en face</li>
                                            <li>L’absence de responsabilisation des différents acteurs économiques qui attendent passivement que les autres impulsent le changement</li>
                                            <li>Une société de plus en plus individualiste qui pousse chacun de nous à prioriser son petit confort personnel au détriment des intérêts collectifs et des générations futures</li>
                                            <li>Personne ne sait par quoi commencer</li>
                                        </ul>
                                        <p>Une chose est certaine, <span className="bold">nous sommes tous responsables</span> (états, entreprises, individus) et nous devons tous agir avant qu’il ne soit vraiment trop tard.</p>
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                    <Typography
                                        component="h2"
                                    >
                                        Il est encore temps d’agir !
                                    </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                    <Typography>
                                        <p>Le combat à venir est colossal. Il doit être mené à <span className="bold">tous les niveaux de la société</span> et à <span className="bold">l’échelle planétaire</span>. Il coutera beaucoup d’argent mais permettra aux générations futures de pouvoir profiter de cette magnifique planète dont nous disposons.   </p>
                                        <p>Chez acacia, nous sommes convaincus que la somme de <span className="bold">petites actions individuelles</span> permettra d’accomplir ces <span className="bold">grands défis collectifs</span> que nous ne pouvons plus ignorer. </p>
                                        <p>Notre mission ? Vous permettre de <span className="bold">devenir acteur</span> des changements structurels nécessaires pour vivre dans un monde meilleur : <i>plus sain, plus collectif, plus durable, plus respectueux</i>. </p>
                                        <p>L’<span className="bold">application acacia</span> vous permet donc de découvrir des centaines d’actions réalisables au quotidien pour <span className="bold">améliorer votre hygiène de vie</span> petit à petit, jour après jour. Chaque action est classée en fonction de sa complexité et de son impact afin de vous aider à les prioriser dans leur réalisation. </p>
                                    </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </StyledEngineProvider>
                        </div>
                        
                        
                        
                        
                        
                    </div>
                </section>

            </main>
            <Footer />
        </div>  
    )
}

export default ManifestoPage