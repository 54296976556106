import React, {useState} from "react"
import {SecondaryCTA} from "../../../components"
import "./contactForm.css"
import db from "../../../firebase.js"
import { collection, addDoc } from "firebase/firestore"
import { Link } from "react-router-dom"

function ContactForm(props) {

    const [formData, setFormData] = useState(
        {
            email: "",
            subject: "",
            comments: "",
        }
    )

    const [isSubmitted, setIsSubmitted] = useState(false)

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

    async function handleSubmit(event) {
        event.preventDefault()
        setIsSubmitted(true)

        try {
            const docRef = await addDoc(collection(db, "emails"), {
              to: ["contact@acaciapp.com"],
              message: {
                subject: `Nouveau message de ${formData.email} - ${formData.subject}`,
                text: ` <p> email : ${formData.email}</p>
                        <p> sujet : ${formData.subject}</p>
                        <p> texte : ${formData.comments}</p>`
              } 
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    let messageSent = "Votre message a bien été envoyé. Nous allons y répondre dans les plus brefs délais."


    return (
        <div>
            <form className="contactform" onSubmit={handleSubmit}>
            
                <label className="contactform-label" htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    required
                    placeholder="nipolas.hublot@gmail.com"
                    onChange={handleChange}
                    name="email"
                    value={formData.email}
                />

                <label className="contactform-label" htmlFor="subject">Sujet</label>
                <input
                    type="text"
                    id="subject"
                    required
                    placeholder="Proposer de nouvelles actions"
                    onChange={handleChange}
                    name="subject"
                    value={formData.subject}
                />

                <label className="contactform-label" htmlFor="comments">Détails</label>
                <textarea 
                    value={formData.comments}
                    id="comments"
                    required
                    placeholder="Votre message ici"
                    onChange={handleChange}
                    name="comments"
                />
    
                {isSubmitted && <div className="success-message">{messageSent}</div>}

                <button>
                    <SecondaryCTA text="ENVOYER" class={isSubmitted && "cta-disabled"} />
                </button>

            </form>

            {isSubmitted ?
                            <Link to="/" className="link-router">
                                <SecondaryCTA text="RETOUR À L'ACCUEIL"/>
                            </Link> :
                            ""}

        </div>
    )
}

export default ContactForm