// TO CHANGE THE COLOR OF ACTIONS (Thumbs, pages) based on their respective category
function adjustActionColor(category) {
    let actionColor
    if (category === "Alimentation") {
        actionColor = "#EA907A"
    } else if (category === "Sommeil") {
        actionColor = "#1572A1"
    } else if (category === "Environnement") {
        actionColor = "#87AAAA"
    } else if (category === "Activité Physique") {
        actionColor = "#A267AC"
    } else {
        actionColor = "#555555"
    }

    return actionColor
} 


export default adjustActionColor