import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGyshrikEmoIkWRwugcw8J5GgKP0DHHjA",
  authDomain: "acacia-44ebf.firebaseapp.com",
  projectId: "acacia-44ebf",
  storageBucket: "acacia-44ebf.appspot.com",
  messagingSenderId: "830835609821",
  appId: "1:830835609821:web:4bbe92f3395817d42b0b34",
  measurementId: "G-ZRN1PNCW2M"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Google Analytics
// eslint-disable-next-line
const analytics = getAnalytics(app);

// Initialize Cloud Firestore and get a reference to the service
// eslint-disable-next-line
const db = getFirestore(app);

export default db