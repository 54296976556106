import React from "react"
import {NavBar, ContactForm, Footer} from "../../../components"
import "./contactPage.css"

function ContactPage() {
    return (
        <div className="contact-page">
            <NavBar />
            <main className="main-container">
                <section className="header-bg">
                    <div className="page-section">
                        <div className="header-top">
                            <h1>Contactez-nous si vous avez la moindre<span className="special-font"> question </span></h1>
                            <h3>Que vous souhaitiez <strong>proposer des actions</strong> ou tout simplement <strong>rejoindre la communauté</strong> !</h3>
                        </div>
                        <ContactForm className="contact-form" />
                    </div>    
                </section>
            </main>
            <Footer />

        </div>  
    )
}

export default ContactPage