import React, {useRef} from "react"
import {Link} from "react-router-dom"
import {NavBar, PrimaryCTA, SecondaryCTA, ActionThumbMini, LeadForm, Footer} from "../../../components"
import "./partnersPage.css"
import partnersIntro from "../../../assets/images/partners-main.png"
import acaciaManifesto from "../../../assets/images/homepage-manifesto.png"
import partnersExplore from "../../../assets/images/partners-explore.png"
import partnersProfile from "../../../assets/images/partners-profile.png"

import actions from "../../../assets/data/actions"
// import { FaChevronDown } from "react-icons/fa"


function PartnersPage() {

    // To manage scroll to lead form
    const contribute = useRef(null)
    function scrollToDiv() {
        contribute.current.scrollIntoView()
    }   

    // To render the actions array into the section
    const allMiniThumbs = actions.map(item => 
        <Link to="/soon" className="link-router">
            <ActionThumbMini
                key={item.id}
                title={item.title}
                points={item.points}
                category={item.mainCategory}
            />
        </Link>)

    return (
        <div className="partners-page">
            <NavBar />
            <main className="main-container">
                
                <section className="header-bg">
                    <div className="page-header">
                        <div className="header-top">
                            <h1><strong>ONGs</strong> : amplifiez <span className="special-font"> l'impact </span> de vos actions</h1>
                            <h3>L'outil acacia vous permet de donner plus de <span className="special-font"> visibilité </span> à vos <strong>actions</strong> et de les <strong>ancrer</strong> dans le <span className="special-font"> quotidien </span> de vos militants</h3>
                        </div>
                        <img src={partnersIntro} alt="screenshots acacia app" className="section-image"/>
                    </div>
                    
                    <div onClick={scrollToDiv}>
                        <PrimaryCTA text="Devenir contributeur" className="main-cta" />
                    </div>
                    
                    {/* <FaChevronDown className="chevron-bottom"/> */}
                </section>

                {/* MANIFESTO */}
                <section className="section-bg orange-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Des <span className="special-font"> actions </span> plutôt que des <strong>mots</strong></h2>
                            <h3>Nos modes de consommation actuels sont en train de <strong>détruire notre santé</strong> et celle de la <strong>planète</strong>. Il n’est jamais trop tard pour <strong>agir</strong>. Nous sommes convaincus que la somme de <strong>petites actions individuelles</strong> permettra d’accomplir les <strong>grands défis collectifs</strong> que nous ne pouvons plus ignorer.</h3>
                        </div>
                        <img src={acaciaManifesto} alt="manifesto acacia" className="section-image"/>
                        <Link to="/manifesto" className="link-router">
                            <SecondaryCTA text="NOTRE MANIFESTO"/>
                        </Link>
                    </div>
                    
                </section>

                {/* Discover : FIRST section of the value proposition  */}
                <section className="section-bg" id="discover">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Engagez vos <span className="special-font"> militants </span> au <strong>quotidien</strong></h2>
                            <h3>Proposez-leur une liste d’actions <strong>claires et précises</strong> qu’ils peuvent intégrer dans leur <strong>routine</strong> du quotidien progressivement.</h3>
                        </div>
                        <div className="section-slider">
                            {allMiniThumbs}
                        </div>
                    </div>
                    
                </section>

                <div className="section-divider green-bg">
                    <div className="section-divider-line"></div>
                </div>
                

                {/* Implement : SECOND section of the value proposition  */}
                <section className="section-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Acquiérez de <span className="special-font"> nouveaux membres </span>  <strong>simplement</strong></h2>
                            <h3>Bénéficiez de la <strong>visibilité</strong> donnée à vos actions auprès de l’intégralité de la <strong>communauté</strong> acacia.</h3>
                        </div>
                        <img src={partnersExplore} alt="screenshots acacia app" className="section-image"/>
                    </div>
                </section>

                <div className="section-divider green-bg">
                    <div className="section-divider-line"></div>
                </div>
                
                {/* Follow : THIRD section of the value proposition  */}
                <section className="section-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Communiquez auprès d'une <span className="special-font"> audience </span> <strong>qualifiée</strong></h2>
                            <h3>Réalisez des <strong>campagnes</strong> de communication auprès d’utilisateurs déjà <strong>sensibles et intéressés</strong> par votre cause.</h3>
                        </div>
                        <img src={partnersProfile} alt="screenshots acacia app" className="section-image"/>
                    </div>
                    
                </section>

                {/* CONTACT */}
                <section className="section-bg white-bg" ref={contribute}>
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Demandez une <span className="special-font"> démonstration </span> d'acacia</h2>
                            <h3>Laissez-nous vos <strong>coordonnées</strong> et nous vous rappellerons dans les <strong>meilleurs délais</strong>.</h3>
                        </div>
                        <LeadForm className="contact-form" />
                    </div>
                    
                </section>

            </main>
            <Footer />
        </div>
    )
}

export default PartnersPage