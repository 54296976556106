import React from "react"
import {NavBar, Footer, SecondaryCTA} from "../../../components"
import {Link} from "react-router-dom"

function NotFoundPage() {
    return (
        <div className="about-page">
            <NavBar />
            <main className="main-container">
                <section className="header-bg">
                    <div className="page-header">
                        <div className="header-top">
                            <h1><span className="special-font">Oups ! </span> Petit problème</h1>
                            <h3>Cette page n’existe plus ou n’a jamais existée. Nous vous invitons à retournez sur la page d’accueil.</h3>
                        </div>
                    </div>
                    <Link to="/" className="link-router">
                        <SecondaryCTA text="RETOUR À L'ACCUEIL"/>
                    </Link>
                    {/* <FaChevronDown className="chevron-bottom"/> */}
                </section>
            </main>
            <Footer />
        </div>  
    )
}

export default NotFoundPage