import React from "react"
import {Link} from "react-router-dom"
import {NavBar, PrimaryCTA, SecondaryCTA, ActionThumbMini, ContactForm, Footer} from "../../../components"
import "./homepage.css"
import acaciaIntro from "../../../assets/images/acacia-intro.png"
import acaciaImplement from "../../../assets/images/homepage-implement.png"
import acaciaFollow from "../../../assets/images/homepage-follow.png"
import acaciaManifesto from "../../../assets/images/homepage-manifesto.png"
import actions from "../../../assets/data/actions"
// import { FaChevronDown } from "react-icons/fa"


function Homepage(props) {

    const allMiniThumbs = actions.map(item => 
        <Link to="/soon" className="link-router">
            <ActionThumbMini
                key={item.id}
                title={item.title}
                points={item.points}
                category={item.mainCategory}
            />
        </Link>) /* renders the actions array into the homepage */
    
    return (
        <div className="home-page">
            <NavBar />
            <main className="main-container">
                
                <section className="header-bg">
                    <div className="page-header">
                        <div className="header-top">
                            <h1>Une meilleure <span className="special-font"> hygiène de vie </span> jour après jour</h1>
                            <h3>L’application des <strong>actions du quotidien</strong> pour prendre soin de sa <span className="special-font"> santé </span> et de <span className="special-font"> l’environnement </span></h3>
                        </div>
                        <img src={acaciaIntro} alt="screenshots acacia app" className="section-image"/>
                    </div>
                    <Link to="/soon" className="link-router">
                        <PrimaryCTA text="Commencer" className="main-cta"/>
                    </Link>
                    {/* <FaChevronDown className="chevron-bottom"/> */}
                </section>

                {/* Discover : FIRST section of the value proposition  */}
                <section className="section-bg" id="discover">
                    <div className="page-section">
                        <div className="section-top">
                            <h2><span className="special-font">Découvrez </span>  des centaines d’<strong>actions</strong> à adopter au <strong>quotidien</strong></h2>
                            <h3>Les actions sont proposées par une sélection des <strong>meilleurs experts</strong> dans chaque domaine : ONGs, médecins, chercheurs, associations, fédérations.</h3>
                        </div>
                        <div className="section-slider">
                            {allMiniThumbs}
                        </div>
                    </div>
                    
                </section>

                <div className="section-divider green-bg">
                    <div className="section-divider-line"></div>
                </div>
                

                {/* Implement : SECOND section of the value proposition  */}
                <section className="section-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2><span className="special-font">Réalisez </span>  progressivement les <strong>actions</strong> que vous avez <strong>choisi</strong></h2>
                            <h3>Les actions sont classées en fonction de leur <strong>complexité</strong> et de leur <strong>impact</strong> pour vous aider à les prioriser.</h3>
                        </div>
                        <img src={acaciaImplement} alt="screenshots acacia app" className="section-image"/>
                    </div>
                </section>

                <div className="section-divider green-bg">
                    <div className="section-divider-line"></div>
                </div>
                
                {/* Follow : THIRD section of the value proposition  */}
                <section className="section-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2><span className="special-font">Suivez </span> les <strong>actions</strong> réalisées par vos <strong>proches</strong></h2>
                            <h3>Les actions réalisées vous rapportent des <strong>acacias</strong> afin que vous puissiez vous <strong>motiver</strong> avec votre famille, vos collègues et vos amis.</h3>
                        </div>
                        <img src={acaciaFollow} alt="screenshots acacia app" className="section-image"/>
                    </div>
                    
                </section>

                {/* MANIFESTO */}
                <section className="section-bg orange-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Des <span className="special-font"> actions </span> plutôt que des <strong>mots</strong></h2>
                            <h3>Nos modes de consommation actuels sont en train de <strong>détruire notre santé</strong> et celle de la <strong>planète</strong>. <br /><br />Il n’est jamais trop tard pour <strong>agir</strong>. Nous sommes convaincus que la somme de <strong>petites actions individuelles</strong> permettra d’accomplir les <strong>grands défis collectifs</strong> que nous ne pouvons plus ignorer.</h3>
                        </div>
                        <img src={acaciaManifesto} alt="manifesto acacia" className="section-image"/>
                        <Link to="/manifesto" className="link-router">
                            <SecondaryCTA text="NOTRE MANIFESTO"/>
                        </Link>
                    </div>
                    
                </section>

                {/* CONTACT */}
                <section className="section-bg white-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2><strong>Contactez-nous</strong> si vous avez la moindre <span className="special-font"> question </span></h2>
                            <h3>Que vous souhaitiez proposer des <strong>actions</strong> ou tout simplement rejoindre la <strong>communauté</strong> !</h3>
                        </div>
                        <ContactForm className="contact-form" />
                    </div>
                    
                </section>

            </main>
            <Footer />
        </div>
    )
}

export default Homepage
