export default [
    {
        id: "1",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Manger des fruits & légumes de saison uniquement",
        mainCategory: "Alimentation",
        emoji: "🥗",
        points: "100",
        completionRate: 0.5,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 201,
        comments: {
            total: 15,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "2",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Prendre des douches de 2 minutes maximum",
        mainCategory: "Environnement",
        emoji: "🚿",
        points: "300",
        completionRate: 0.2,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 34,
        comments: {
            total: 54,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "3",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Ne plus acheter de bouteilles en plastique",
        mainCategory: "Environnement",
        emoji: "🌍",
        points: "80",
        completionRate: 0,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 76,
        comments: {
            total: 12,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "4",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Arrêter de regarder les écrans une heure avant d’aller se coucher",
        mainCategory: "Sommeil",
        emoji: "🖥",
        points: "150",
        completionRate: 0.5,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 76,
        comments: {
            total: 8,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "5",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Limiter l’alcool à 10 doses par semaine",
        mainCategory: "Alimentation",
        emoji: "🍷",
        points: "250",
        completionRate: 0.2,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 107,
        comments: {
            total: 18,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "6",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Prendre les escaliers plutot que l’ascenseur ou les escalators",
        mainCategory: "Activité Physique",
        emoji: "🏆",
        points: "100",
        completionRate: 1,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 569,
        comments: {
            total: 3,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "7",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Éteindre les lumières d’une pièce lorsque nous y sommes pas",
        mainCategory: "Environnement",
        emoji: "💡",
        points: "60",
        completionRate: 0,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 429,
        comments: {
            total: 29,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "8",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Ne pas jeter ses mégots dans la rue ou la nature",
        mainCategory: "Environnement",
        emoji: "🚬",
        points: "120",
        completionRate: 0,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 49,
        comments: {
            total: 2,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "9",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Éviter de boire des jus de fruits pressés",
        mainCategory: "Alimentation",
        emoji: "🍊",
        points: "60",
        completionRate: 0.2,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 100,
        comments: {
            total: 10,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
    {
        id: "10",
        author: "Maxime Courtaigne",
        authorImg: "",
        contributors: ["contrib1", "contrib2", "contrib3"],
        title: "Préférer le vélo pour les trajets de moins de 5km",
        mainCategory: "Environnement",
        emoji: "🚲",
        points: "170",
        completionRate: 0,
        facts: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.",
        isSelected: false,
        isAchieved: false,
        TotalAchieved: 34,
        comments: {
            total: 1,
            detailed: ["comment1", "comment2", "comment3"],
        },
        ratings: {
            complexity: 2,
            healthImpact: 3,
            earthImpact: 5,
        },
        tags: ["tag1", "tag2", "tag3"],
        articles: ["article1", "article2", "article3"],
    },
]  