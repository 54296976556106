import React from "react"
import {NavBar, Footer} from "../../../components"
import "./aboutPage.css"

function AboutPage() {
    return (
        <div className="about-page">
            <NavBar />
            <main className="main-container">
                <section className="header-bg">
                    <div className="page-header">
                        <div className="header-top">
                            <h1>L' <span className="special-font"> application éco-citoyenne </span> pour améliorer son hygiène de vie jour après jour </h1>
                            <h3>En cours de développement, l’application acacia va bientôt vous permettre de découvrir des centaines <strong>d’actions du quotidien</strong> pour mieux prendre soin de sa santé et de l'environnement. </h3>
                            <h3>Choisissez les <strong>thématiques importantes</strong> pour vous et commencer à modifier vos habitudes petit à petit. </h3>
                            <h3>Chaque action est classée en fonction de sa complexité et de son impact afin de vous <strong>aider à la prioriser</strong>. </h3>
                        </div>
                        {/* Header image */}
                    </div>
                </section>

                <section className="section-bg orange-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Notre <span className="special-font"> mission </span></h2>
                            <h3>Vous permettre de <strong>devenir acteur</strong> des changements structurels nécessaires pour vivre dans un <strong>monde meilleur</strong> : <i>plus sain, plus collectif, plus durable, plus respectueux</i>.</h3>
                        </div>
                    </div>
                </section>

                <div className="section-divider orange-bg">
                    <div className="section-divider-line"></div>
                </div>

                <section className="section-bg orange-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Notre <span className="special-font"> vision </span></h2>
                            <h3>La somme de <strong>petites actions individuelles</strong> nous permettra d’accomplir les <strong>grands défis collectifs</strong> que nous ne pouvons plus ignorer.</h3>
                        </div>
                    </div>
                </section>

                <section className="section-bg white-bg">
                    <div className="page-section">
                        <div className="section-top">
                            <h2>Notre <span className="special-font"> histoire </span></h2>
                            <h3>Fondée en 2022 par <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/maximecourtaigne/">Maxime Courtaigne</a>, l'application acacia est le fruit d'un <strong>long cheminement</strong> personnel et professionnel mais aussi d'une <strong>forte volonté</strong> de vivre dans un monde meilleur. </h3>
                        </div>
                    </div>
                    
                </section>

            </main>
            <Footer />
        </div>  
    )
}

export default AboutPage