import React, {useState} from "react"
import {SecondaryCTA} from "../../../components"
import "./leadForm.css"
import db from "../../../firebase.js"
import { collection, addDoc } from "firebase/firestore"

function LeadForm(props) {

    const [formData, setFormData] = useState(
        {
            lead_email: "",
            lead_name: "",
            lead_url: "",
            lead_phone: "",
        }
    )

    const [isSubmitted, setIsSubmitted] = useState(false)

    function handleChange(event) {
        const {name, value, type, checked} = event.target
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }
        })
    }

        async function handleSubmit(event) {
        event.preventDefault()
        setIsSubmitted(true)

        try {
            const docRef = await addDoc(collection(db, "emails"), {
              to: ["contact@acaciapp.com"],
              message: {
                subject: `Nouveau lead de ${formData.lead_email} - ${formData.lead_name}`,
                text: ` <p> email : ${formData.lead_email}</p>
                        <p> nom : ${formData.lead_name}</p>
                        <p> url : ${formData.lead_url}</p>
                        <p> phone : ${formData.lead_phone}</p>`
              } 
            });
            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
        }

    }

    let messageSent = "Votre demande a bien été enregistrée. Nous allons vous contacter dans les plus brefs délais."


    return (
        <div>
            <form className="leadform" onSubmit={handleSubmit}>
            
                <label className="contactform-label" htmlFor="email">Votre adresse email</label>
                <input
                    type="email"
                    id="email"
                    required
                    placeholder="nipolas.hublot@gmail.com"
                    onChange={handleChange}
                    name="lead_email"
                    value={formData.lead_email}
                />

                <label className="contactform-label" htmlFor="name">Nom de votre ONG</label>
                <input
                    type="text"
                    id="name"
                    required
                    placeholder="No Planet B"
                    onChange={handleChange}
                    name="lead_name"
                    value={formData.lead_name}
                />

                <label className="contactform-label" htmlFor="url">Site internet de votre ONG</label>
                <input
                    type="text"
                    id="url"
                    required
                    placeholder="www.noplanetb.fr"
                    onChange={handleChange}
                    name="lead_url"
                    value={formData.lead_url}
                />

                <label className="contactform-label" htmlFor="phone">Numéro de téléphone</label>
                <input
                    type="tel"
                    id="phone"
                    required
                    placeholder="06 12 34 56 78"
                    onChange={handleChange}
                    name="lead_phone"
                    value={formData.lead_phone}
                />
    
                {isSubmitted && <div className="success-message">{messageSent}</div>}

                <button>
                    <SecondaryCTA text="ENVOYER" class={isSubmitted && "cta-disabled"} />
                </button>

            </form>
        </div>
    )
}

export default LeadForm