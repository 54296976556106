import React from "react"

function AcaciaIcon(props) {
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9612 45C18.2172 26.9184 24.1354 20.238 38.4509 15.3483" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse rx="4.75687" ry="2.46267" transform="matrix(0.953786 -0.300488 0.278328 0.960486 41.7775 14.2011)" fill={props.color}/>
            <ellipse rx="4.75243" ry="2.46481" transform="matrix(0.965841 0.259135 -0.239592 0.970874 20.9465 40.0115)" fill={props.color}/>
            <ellipse rx="4.80856" ry="2.43745" transform="matrix(0.803141 0.595789 -0.563621 0.826033 8.23575 34.0641)" fill={props.color}/>
            <path d="M11.3256 36.1045C13.1772 38.3819 14.5164 38.9228 17.3393 39.0285" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse rx="4.757" ry="2.45928" transform="matrix(0.956313 0.294839 -0.271291 0.961794 24.0436 34.3112)" fill={props.color}/>
            <ellipse rx="4.81332" ry="2.43265" transform="matrix(0.782583 0.624784 -0.590812 0.805125 11.5397 27.905)" fill={props.color}/>
            <path d="M14.558 30.0565C16.3346 32.3977 17.655 32.9877 20.4715 33.1999" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse rx="4.77013" ry="2.44607" transform="matrix(0.927079 0.3816 -0.349743 0.934345 26.9211 28.935)" fill={props.color}/>
            <ellipse rx="4.82529" ry="2.42103" transform="matrix(0.727216 0.693424 -0.655992 0.7485 15.0003 21.4168)" fill={props.color}/>
            <path d="M17.824 23.8346C19.4038 26.3235 20.6703 27.0328 23.4558 27.5079" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse rx="4.78151" ry="2.43656" transform="matrix(0.898388 0.44809 -0.411384 0.907479 32.6814 24.8399)" fill={props.color}/>
            <ellipse rx="4.8342" ry="2.4127" transform="matrix(0.679106 0.744171 -0.704939 0.698898 21.2926 16.4728)" fill={props.color}/>
            <path d="M23.9453 19.0905C25.3585 21.6851 26.5749 22.4855 29.3204 23.1666" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse rx="4.80349" ry="2.42191" transform="matrix(0.827931 0.572189 -0.5302 0.840774 38.6576 21.3281)" fill={props.color}/>
            <ellipse rx="4.84768" ry="2.40069" transform="matrix(0.573696 0.833916 -0.792914 0.589252 28.4964 11.3899)" fill={props.color}/>
            <path d="M30.7701 14.368C31.8288 17.1385 32.9273 18.1083 35.5539 19.1857" stroke={props.color} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default AcaciaIcon