import React from "react"
import {Routes, Route} from "react-router-dom"
import {HomePage, AboutPage, PartnersPage, SoonPage, ManifestoPage, ContactPage, LegalPage, NotFoundPage} from "./screens" /* Importing Screens */
import ScrollToTop from "./hooks/ScrollToTop"

function App() {


    return (
        <div>
            <ScrollToTop>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/partners" element={<PartnersPage />} />
                    <Route path="/soon" element={<SoonPage />} />
                    <Route path="/manifesto" element={<ManifestoPage />} />
                    <Route path="/legal" element={<LegalPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </ScrollToTop>
        </div>
    )
}

export default App